<template>
  <div class="row">
    <div class="col-sm-12 mt-2">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
              <h3 class="text-primary mb-5 text-center">Create Category</h3>
              <div class="form-group">
                <label for="name"
                  >Name<span class="text-danger">*</span> :</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="type category name.."
                  v-model="name"
                />
              </div>
              <div class="form-group">
                <label for="status">Items Type :</label>
                <b-form-select
                  class="form-control"
                  id="status"
                  v-model="item_type"
                  :options="options"
                >
                </b-form-select>
              </div>
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="is_active"
                  v-model="is_active"
                />
                <label class="form-check-label" for="is_active"
                  >Is Active</label
                >
              </div>

              <button
                type="submit"
                class="btn btn-block btn-primary mt-5"
                @click="addCategory"
              >
                <i class="fa fa-plus"></i>
                Create Category
              </button>
            </div>

            <div class="col-sm-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import constant from '../../store/constant';

const itemType = Object.entries(constant.ITEM_TYPES).map((val) => ({
  text: val[1],
  value: val[1].toLowerCase(),
}));
export default {
  name: 'AddCategory',
  data() {
    return {
      name: '',
      is_active: false,
      item_type: 'magazine',
      options: [...itemType],
    };
  },
  computed: {
    ...mapState({
      successMessage: (state) => state.category.successMessage,
      errorMessage: (state) => state.category.errorMessage,
    }),
  },
  watch: {
    successMessage: function() {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
      this.$router.push('/category');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
  },
  methods: {
    ...mapActions('category', ['createCategory']),

    async addCategory() {
      const payload = {
        name: this.name,
        item_type: this.item_type,
        is_active: this.is_active,
      };
      this.createCategory(payload);
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
